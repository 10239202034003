import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
    return (
        <>
            <div className="App">
                <div className="container">
                    <header>
                        <div className="header">
                            <div className="memeism-text">
                                <b>MEMEISM</b>
                                <h1 data-text="MEMEISM">MEMEISM</h1>
                            </div>

                            <p>
                                Memeism is a religion founded on December 15th-16th, 2017 in Spain, Madrid.
                                The religion praises and swears loyalty to the gods of Ooof, Kek, Yee, Pepe the Frog,
                                and Gabe
                                the dog.
                            </p>
                            <p>
                                On November 10th, 2024, Memeism got its digital rebirth on the Solana network, through
                                the
                                legendary halls of Pumpfun — the degens' paradise!
                            </p>
                            <span>HEi1wV81wVGhyAFC2a8dTJ5iUi48SQZTCx5VqjKzpump</span>
                            <p>
                                A few scheming scammers tried to snuff out the faith, but our resolve remains
                                unshakable.
                                Memeism shall be worshipped on every [DEX|CEX] platform, spreading its all-encompassing
                                power throughout the digital realms.
                            </p>
                            <h3>Meme on, for Memeism shall reign eternal!</h3>
                        </div>

                        <div className="memeism">
                            <img src={"/assets/img/memeism.jpg"} alt="Memeism"/>
                            <audio controls autoPlay loop>
                                <source src="/assets/audio/despacito.mp3" type="audio/mpeg"/>
                                Your browser does not support the audio element.
                            </audio>

                            <a className="listen-more"
                               href={"https://www.youtube.com/watch?v=i11RMG_U3R4&list=RDQMSdfCGEs32Mg&start_radio=1"}
                               target="_blank"> Listen more Gabe the Dog</a>
                        </div>
                    </header>
                </div>
            </div>

            <div className="info-block">
                <div className={"container"}>
                    <div className={"flex"}>
                        <div>
                            <h2>FOLOW US</h2>
                            <div className="socials">
                                <a className={'social'} target="_blank"
                                   href={"https://dexscreener.com/solana/6k7schtr2iv3tbayrfu4jwlriahdhja1i1v6q93emu8"}>
                                    <img src="/assets/img/dex.png" alt="DexScreneer" className="dex"/>
                                </a>

                                <a className={'social'} target="_blank"
                                   href={"https://x.com/Memeism_Sol"}>
                                    <img src="/assets/img/x.png" alt="X/Twitter" className="dex"/>
                                </a>

                                <a className={'social'} target="_blank"
                                   href={"https://t.me/MemeismPortal"}>
                                    <img src="/assets/img/telegram.png" alt="Telegram" className="dex"/>
                                </a>
                            </div>
                        </div>
                        <div>
                            <h2 className={"color-blue"}>BUY MEMEISM</h2>
                            <a target="_blank" className={"buy"}
                               href={"https://jup.ag/swap/SOL-HEi1wV81wVGhyAFC2a8dTJ5iUi48SQZTCx5VqjKzpump"}>
                                Click here to buy MEMEISM
                            </a>
                        </div>

                        <div>
                            <h2 className={"color-yellow"}>PROGRAMMED</h2>
                            <span>100B+</span>
                        </div>

                        <div>
                            <h2 className={"color-purple"}>TRUST LEVEL</h2>
                            <span>TRUST ME</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="religion">
                <div className={"container"}>
                    <h2>Religion</h2>
                    <b>The religion's book is called the Dankatron, and has the following theses:</b>
                    <ul>
                        <li>Any religion shall be respected but specially meme religions, as our god Pepe the Frog
                            wants.
                        </li>
                        <li>The Sacred Places are the Dankest Church in Madrid, and the holy dirt tower at
                            Reeerusalam.
                        </li>
                        <li>Our gods are 5: Ooof (God of death), Kek (Dankest evar), Yee (Diplomacy through memes), Pepe
                            the
                            Frog (Peace pls god), and Gabe the dog (the god of pets with his heavenly borks).
                        </li>
                        <li>We come from the Wokist Reform, thus we shall respect the wokist reformists.</li>
                        <li>No violence between members or Ooof will punish the aggressor.</li>
                        <li>Our Prophet is PewDiePie, our poup is smg4 and our gods Ooof, Pingus, Yee, Pepe the Frog and
                            Gabe
                            the dog.
                        </li>
                    </ul>
                    <a href="https://earthmcclassic.fandom.com/wiki/Memeism" target="_blank">Read more</a>
                </div>
            </div>


            <h1 className={"begin"}>STORY THE BEGINNING. . .</h1>


        </>
    )
        ;
}

export default App;
